import React from 'react';
import { string } from 'prop-types';

const namespace = 'ui-search-footer-disclaimer';

const FooterDisclaimer = ({ text }) => (
  <span className={namespace}>
    <p className={`${namespace}__text`}>{text}</p>
  </span>
);

FooterDisclaimer.propTypes = {
  text: string.isRequired,
};

export default FooterDisclaimer;
